import api from "../apis";
import _ from "lodash";

export const CASE_MULTI_FETCH = "case/CASE_MULTI_FETCH";
export const CASE_CREATE = "case/CASE_CREATE";
export const CASE_FETCH = "case/CASE_FETCH";
export const CASE_DELETE = "case/CASE_DELETE";
export const CASE_RELEASE = "case/CASE_RELEASE";
export const CASE_EDIT = "case/CASE_EDIT";
export const CASE_CLEAR_RESULT = "case/CASE_CLEAR_RESULT";
export const CASE_MARK_LOADING = "case/CASE_MARK_LOADING";
export const CASE_SET_FILTER = "case/CASE_SET_FILTER";

const defaultState = {
  data: {},
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CASE_MULTI_FETCH:
      return {
        ...state,
        data: _.mapKeys(action.payload.rows, "id"),
        meta: {
          ...state.meta,
          loading: false,
          pageCount: Math.ceil(action.payload.total / action.payload.pageSize),
        },
      };
    case CASE_FETCH:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload },
      };
    case CASE_CLEAR_RESULT:
      return {
        ...state,
        data: {},
        meta: { ...state.meta, loading: false, total: 0 },
      };
    case CASE_MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case CASE_DELETE:
      return {
        ...state,
        data: _.omit(state.data, action.payload),
      };
    case CASE_RELEASE:
      return {
        ...state,
        data: _.omit(state.data, action.payload),
      };
    case CASE_EDIT:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload },
      };
    case CASE_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
        },
      };
    default:
      return state;
  }
}

export const caseFetch = (id) => async (dispatch) => {
  const response = await api.get(`/api/cases/${id}`);
  dispatch({ type: CASE_FETCH, payload: response.data });
};

export const caseCreate = (formsValues) => async (dispatch) => {
  await api.post("/api/cases", formsValues);
};
export const caseClearResult = () => {
  return { type: CASE_CLEAR_RESULT };
};

export const caseEdit = (id, formValues) => async (dispatch) => {
  //   formValues._method = "PUT";
  await api.put(`/api/cases/${id}`, formValues);
  // dispatch({ type: EDIT_ARREST, payload: response.data });
};

export const caseDelete = (id) => async (dispatch) => {
  await api.delete(`/api/cases/${id}`);
  dispatch({ type: CASE_DELETE, payload: id });
  // if (deleteId === deleteIdRef.current) {
  // }
};

export const caseRelease = (id) => async (dispatch) => {
  await api.post(`/api/release`, { id: id });
  dispatch({ type: CASE_RELEASE, payload: id });
  // if (deleteId === deleteIdRef.current) {
  // }
};
