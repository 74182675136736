import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Grid, Modal, Paper, Snackbar, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import {
  assignCaseAuditChangePage,
  assignCaseAuditChangePageSize,
  assignCaseAuditClearResult,
  assignCaseAuditFetch,
  assignCaseAuditForceReload,
  assignCaseAuditSetFilter,
} from "../../../../ducks/auditList";
import verifyEmailLogSearchForm from "./AssignCaseLogSearchForm";
import { render } from "@testing-library/react";
import { red } from "@mui/material/colors";
import { max, min } from "lodash";

export default function SettingverifyEmailLog() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(assignCaseAuditClearResult());
      dispatch(assignCaseAuditSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector(
    (state) => state?.auditList?.assign_case_audit?.data
  );
  const total = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.total
  );
  const loading = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.loading
  );
  const forceReload = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.forceReload
  );
  const page = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.page
  );
  const pageSize = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.pageSize
  );
  const filter = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.filter
  );

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      assignCaseAuditFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(assignCaseAuditForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 60,
      minWidth: 50,
      maxWidth: 70,
    },
    {
      field: 'user_data_1',
      headerName: "Email",
      sortable: true,
      flex: 1,
      minWidth: 50,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <span>
            {params.row.user_data?.email}
          </span>
        );
      }
    },
    {
      field: "user_data",
      headerName: "User",
      sortable: true,
      width: 120,
      renderCell: (params) => {
        return (
          <span>
            {params.row.user_data.fname + " " + params.row.user_data.lname}
          </span>
        );
      }
    },
    {
      field: "type",
      headerName: "type",
      sortable: true,
      width: 70,
    },
    {
      field: "request",
      headerName: "Case ID",
      sortable: true,
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          params.row.type === 'release') ?
          <span>
            {
              Object.entries(JSON.parse(params.row.request||"{}")).map(
                (el)=>(
                  el[0] === 'id' ? <span key={el[0]}>{el[1]}</span> :''
                )
              )
            }
            </span> :
          <span>
            {
              Object.entries(JSON.parse(params.row.request||"{}")).map(
                (el)=>(
                  el[0] === 'case_id' ? <span key={el[0]}>{
                    Array.isArray(el[1])?el[1].join(', '):el[1]
                  }</span> :''
                )
              )
            }
          </span>
        
      }
    },
    {
      field: "policestation_data",
      headerName: "หน่วนงานที่รับมอบหมาย",
      sortable: true,
      flex: 1,
      minWidth: 50,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <span>
            {params.row.policestation_data?.ORG}
          </span>
        );
        
      }
    },

    {
      field: "case",
      headerName: "เจ้าหน้าที่รับมอบหมาย",
      sortable: true,
      flex: 1,
      minWidth: 50,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <span>
            {JSON.parse(params.row.case).police_name}
          </span>
        );
        
      }
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(assignCaseAuditSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">Case Log</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={verifyEmailLogSearchForm}
            loading={loading}
            onReset={() => dispatch(assignCaseAuditSetFilter({}))}
            showStatusField={reqStatus ? false : true}
            initialValues={{}}
            rows={rows}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows ?? []}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(assignCaseAuditChangePage(page))}
            onPageSizeChange={(pageSize) =>
              dispatch(assignCaseAuditChangePageSize(pageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state?.auditList?.metaAssignCaseAudit?.loading
  );

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(assignCaseAuditForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
