import {
  Add as AddIcon,
  Delete,
  Download,
  List,
  PlaylistRemove,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { caseDelete, caseRelease } from "../../../ducks/case";
import {
  clearResult,
  forceReload,
  markLoading,
  search,
  setFilter,
} from "../../../ducks/caseSearch";
import CaseSearchForm from "./CaseSearchForm";
import ReportCSV from "./exportCSV";
import ReportExcel from "./exportExcel";
import TpoCaseOwnerSearchForm from "./TpoCaseOwnerSearchForm";
import TpoCaseSearchForm from "./TpoCaseSearchForm";
import api from "../../../apis";
import fileDownload from "js-file-download";
import { format, subMonths } from "date-fns";
import { fil } from "date-fns/locale";

export default function CaseListPage() {
  const dispatch = useDispatch();
  const cases = useSelector((state) => state.auth.user.permissions.cases ?? []);
  const assign_case = useSelector(
    (state) => state.auth.user.permissions.assign_case ?? []
  );
  const fetchIdRef = React.useRef(0);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  // tpo case search from modal
  const [openTpoCaseOwnerSearchForm, setOpenTpoCaseOwnerSearchForm] =
    useState(false);
  const handleCloseTpoCaseOwnerSearchForm = () => {
    setOpenTpoCaseOwnerSearchForm(false);
  };

  // tpo case search from modal
  const [openTpoCaseSearchForm, setOpenTpoCaseSearchForm] = useState(false);
  const handleCloseTpoCaseSearchForm = () => {
    setOpenTpoCaseSearchForm(false);
  };

  // case add menu state
  const [anchorEl, setAnchorEl] = React.useState(null);

  const caseMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // delete record confirmation
  const [cfDel, SetCfDel] = useState(null);
  const [cfDelLoading, SetCfDelLoading] = useState(false);
  const handleCfDelClose = () => SetCfDel(null);
  const handleCfDelOk = () => {
    SetCfDelLoading(true);
    dispatch(caseDelete(cfDel.id))
      .then(() => {
        SetToastInfo({ type: "success", message: "ลบสำเร็จ" });
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        dispatch(forceReload());
        handleCfDelClose();
        SetCfDelLoading(false);
      });
  };
  const [cfRelease, SetCfRelease] = useState(null);
  const [cfReleaseLoading, SetCfReleaseLoading] = useState(false);
  const handleCfReleaseClose = () => SetCfRelease(null);
  const handleCfReleaseOk = () => {
    SetCfReleaseLoading(true);
    dispatch(caseRelease(cfRelease.id))
      .then(() => {
        SetToastInfo({ type: "success", message: "ปลด case สำเร็จ" });
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        dispatch(forceReload());
        handleCfReleaseClose();
        SetCfReleaseLoading(false);
      });
  };

  useEffect(() => {
    // Set up
    // let isSubscribed = true;
    return () => {
      // isSubscribed = false;
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const rows = useSelector((state) => state.caseSearch.data);
  const { total, loading, filter, reload } = useSelector(
    (state) => state.caseSearch.meta
  );

  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    dispatch(markLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(
      search(rowsState.pageSize, rowsState.page, filter, fetchId, fetchIdRef)
    ).catch(({ response }) => {
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      dispatch(clearResult());
      dispatch(markLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, rowsState.page, rowsState.pageSize, reload, cfReleaseLoading]);

  const onSearchFormSubmit = (data) => {
    dispatch(setFilter(data));
  };

  const columns = useMemo(() => {
    return [
      ...(cases.includes("view")
        ? [
            {
              field: "action",
              headerName: "จัดการ",
              width: 100,
              renderCell: (params) => {
                return (
                  <Stack spacing={1} direction="row">
                    {cases.includes("view") && (
                      <IconButton
                        type="button"
                        size="small"
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/cases/${params.id}/mgt`}
                      >
                        <List />
                      </IconButton>
                    )}
                    {cases.includes("assign") && (
                      <IconButton
                        type="button"
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() =>
                          SetCfRelease({
                            id: params.id,
                            msg: `ยืนยันการปลด case (${params.id})`,
                          })
                        }
                      >
                        <PlaylistRemove />
                      </IconButton>
                    )}
                    {/* <IconButton
                type="button"
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  SetCfDel({
                    id: params.id,
                    msg: `ยืนยันการลบ การรับแจ้ง (${params.id})`,
                  });
                }}
              >
                <Delete />
              </IconButton> */}
                  </Stack>
                );
              },
            },
          ]
        : []),
      { field: "id", headerName: "ID", maxWidth: 70 },
      {
        field: "date",
        headerName: "วันที่",
        width: 100,
      },
      {
        field: "tpo_case_id",
        headerName: "TPO ID",
        minWidth: 100,
        maxWidth: 130,
      },
      {
        field: "victim_name",
        headerName: "ชื่อผู้เสียหาย",
        minWidth: 150,
        maxWidth: 180,
      },
      {
        field: "police_name",
        headerName: "ชื่อ พงส.",
        minWidth: 150,
        maxWidth: 180,
      },
      {
        field: "job_title",
        headerName: "ตำแหน่ง",
        minWidth: 150,
        maxWidth: 180,
      },
      {
        field: "policestation",
        headerName: "หน่วยงานเจ้าของเรื่อง",
        minWidth: 150,
        maxWidth: 180,
        valueGetter: (params) => {
          return params.row.policestation.ORG_ABBR;
        },
      },
    ];
  }, [cases]);

  const DownloadBtn = (
    <LoadingButton
      id="export-button-excel"
      type="button"
      variant="outlined"
      startIcon={<Download />}
      loadingPosition="start"
      // onClick={() => handleDownload("/api/", setPdfDownloading)}
      aria-controls={openDowload ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={openDowload ? "true" : undefined}
      onClick={handleClickDowload}
      loading={isLoadingExcel || isLoadingCsv || isLoadingPdf}
      disabled={!filter.start_date || !filter.end_date}
    >
      ดาวน์โหลด
    </LoadingButton>
  );

  return (
    <>
      <TpoCaseOwnerSearchForm
        open={openTpoCaseOwnerSearchForm}
        onClose={handleCloseTpoCaseOwnerSearchForm}
      />
      <TpoCaseSearchForm
        open={openTpoCaseSearchForm}
        onClose={handleCloseTpoCaseSearchForm}
      />
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Dialog
        open={cfRelease ? true : false}
        onClose={handleCfDelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการปลด case</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {cfRelease?.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!cfReleaseLoading && (
            <Button onClick={handleCfReleaseClose}>ยกเลิก</Button>
          )}
          <LoadingButton
            onClick={handleCfReleaseOk}
            loading={cfReleaseLoading}
            loadingPosition="start"
            variant="contained"
            autoFocus
            color="error"
          >
            ยืนยัน
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการลบข้อมูล</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {cfDel?.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!cfDelLoading && <Button onClick={handleCfDelClose}>ยกเลิก</Button>}
          <LoadingButton
            onClick={handleCfDelOk}
            loading={cfDelLoading}
            loadingPosition="start"
            startIcon={<Delete />}
            variant="contained"
            autoFocus
            color="error"
          >
            ลบ
          </LoadingButton>
          {/* <Button
            onClick={handleCfDelClose}
            variant="contained"
            color="error"
            autoFocus
            loading
          >
            ลบ
          </Button> */}
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Typography variant="h5">รายการ Case</Typography>
      </Grid>
      {cases.includes("search") && (
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Form
              // validate={validate}
              initialValues={{}}
              onSubmit={onSearchFormSubmit}
              component={CaseSearchForm}
              loading={loading}
              onReset={() => dispatch(setFilter({}))}
            />
            {/* <CaseSearchForm onSubmit={onSearchFormSubmit} loading={loading} /> */}
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 700,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mb: 2 }}
          >
            {cases.includes("download") && (
              <>
                {!filter.start_date || !filter.end_date ? (
                  <Tooltip title="กรุณาเลือกวันที่ ที่ค้นหาก่อนใช้งานปุ่มดาวน์โหลด">
                    <span>{DownloadBtn}</span>
                  </Tooltip>
                ) : (
                  DownloadBtn
                )}
              </>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorElDowload}
              open={openDowload}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  setIsLoadingPdf(true);
                  handleCloseDownload();
                  try {
                    const res = await api.get(`/api/cases`, {
                      responseType: "blob",
                      params: {
                        page_size: rowsState.pageSize,
                        page: rowsState.page,
                        export: "pdf",
                        org_code: filter.policestation_org_code?.org_code,
                        user_id: filter?.police_name?.user_id,
                        search: filter.search,
                        start_date:
                          filter.start_date ??
                          format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                        end_date:
                          filter.end_date ?? format(new Date(), "yyyy-MM-dd"),
                      },
                    });

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];
                    fileName =
                      fileName.at(-1) === '"'
                        ? fileName.slice(1, -1)
                        : fileName;
                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsLoadingPdf(false);
                  }
                }}
              >
                Pdf
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  setIsLoadingExcel(true);
                  handleCloseDownload();
                  try {
                    const res = await api.get(`/api/cases`, {
                      responseType: "blob",
                      params: {
                        page_size: rowsState.pageSize,
                        page: rowsState.page,
                        export: "excel",
                        org_code: filter.policestation_org_code?.org_code,
                        user_id: filter?.police_name?.user_id,
                        search: filter.search,
                        start_date:
                          filter.start_date ??
                          format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                        end_date:
                          filter.end_date ?? format(new Date(), "yyyy-MM-dd"),
                      },
                    });

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];
                    fileName =
                      fileName.at(-1) === '"'
                        ? fileName.slice(1, -1)
                        : fileName;
                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsLoadingExcel(false);
                  }
                }}
              >
                Excel
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  try {
                    handleCloseDownload();
                    setIsLoadingCsv(true);
                    const res = await api.get(`/api/cases`, {
                      responseType: "blob",
                      params: {
                        page_size: rowsState.pageSize,
                        page: rowsState.page,
                        export: "csv",
                        org_code: filter.policestation_org_code?.org_code,
                        user_id: filter?.police_name?.user_id,
                        search: filter.search,
                        start_date:
                          filter.start_date ??
                          format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                        end_date:
                          filter.end_date ?? format(new Date(), "yyyy-MM-dd"),
                      },
                    });

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];
                    fileName =
                      fileName.at(-1) === '"'
                        ? fileName.slice(1, -1)
                        : fileName;
                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsLoadingCsv(false);
                  }
                }}
              >
                CSV
              </MenuItem>
            </Menu>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenTpoCaseSearchForm(true);
              }}
            >
              ยืนยันการรับแจ้งตาม พรก.ฯ(H)
            </Button>
            {/* <Button
              component={Link}
              to="/cases/add"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
            >
              เพิ่ม Case
            </Button> */}
            {cases.includes("create") && (
              <>
                <Button
                  aria-controls={caseMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={caseMenuOpen ? "true" : undefined}
                  onClick={handleClick}
                  variant="contained"
                  color="success"
                  startIcon={<AddIcon />}
                >
                  เพิ่ม Case
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={caseMenuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenTpoCaseOwnerSearchForm(true);
                    }}
                  >
                    case จาก thai police online
                  </MenuItem>
                  <MenuItem component={Link} to="/cases/add">
                    case ทั่วไป
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[10, 20]}
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            onPageSizeChange={(pageSize) =>
              setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
            }
          />
        </Paper>
      </Grid>
    </>
  );
}
