import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Button,
  FormHelperText,
} from "@mui/material";
import React, { useEffect } from "react";
import FieldInput from "../../form-fields/FieldInput";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import SubmitBtn from "../../SubmitBtn";
import { Save } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function ProfileForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  policestationOrgCode
}) {
  const isInternal = useSelector((state) => state.auth.user?.isInternal);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldInput
            name="rank"
            label={isInternal ? "ยศ" : "คำนำหน้า"}
            controlProps={{ fullWidth: true }}
            inputProps={{
              placeholder: isInternal
                ? "คำเต็ม เช่น พันตำรวจโท"
                : "เช่น นาย นาง นางสาว",
            }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="fname"
            label="ชื่อ"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="lname"
            label="สกุล"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name={isInternal ? "job_title" : "position"}
            label="ตำแหน่ง"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isInternal ? (
            <>
              <FieldAsyncAutoComplete
                name="policestation_org_code"
                label="หน่วยงาน"
                required
                controlProp={{ fullWidth: true }}
                basePath={"/api/policestations"}
                itemFormat={(row) => {
                  return { value: row.ORG_CODE, label: row.ORG_ABBR };
                }}
                readOnly={readOnly}
              />
              {policestationOrgCode !== values.policestation_org_code &&<FormHelperText
                id="policestation_org_code-error"
                variant="outlined"
                error
              >
                กรณีเปลี่ยนหน่วยงานที่สังกัด Case ที่ดูแลทั้งหมด จะหายไป
              </FormHelperText>}
            </>
          ) : (
            <FieldAsyncSelect
              name="bank.id"
              label="หน่วยงานภายนอก"
              required
              controlProp={{ fullWidth: true }}
              readOnly={!isInternal}
              disabled={!isInternal}
              basePath={`/api/banks`}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: `${row.name} (${row.short_name})`,
                };
              }}
              inputProps={{ sx: { width: "100%" } }}
            />
          )}
        </Grid>
        <Grid item xs md>
          <FieldInput
            name="pol_tel"
            label="โทรศัพท์"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Divider></Divider>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    if (onCancel) {
                      onCancel(form);
                      return;
                    }
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}
