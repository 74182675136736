import api from "../apis";
import _ from "lodash";

export const POLICE_STATION_CREATE = "masterData/POLICE_STATION_CREATE";
export const POLICE_STATION_FETCH = "masterData/POLICE_STATION_FETCH";
export const POLICE_STATION_FETCH_BY_ID =
  "masterData/POLICE_STATION_FETCH_BY_ID";
export const POLICE_STATION_DELETE = "masterData/POLICE_STATION_DELETE";
export const POLICE_STATION_EDIT = "masterData/POLICE_STATION_EDIT";
export const POLICE_STATION_LOADING = "masterData/POLICE_STATION_LOADING";
export const POLICE_STATION_CLEAR_RESULT =
  "masterData/POLICE_STATION_CLEAR_RESULT";
export const POLICE_STATION_CHANGE_PAGE =
  "masterData/POLICE_STATION_CHANGE_PAGE";
export const POLICE_STATION_CHANGE_PAGE_SIZE =
  "masterData/POLICE_STATION_CHANGE_PAGE_SIZE";
export const POLICE_STATION_FORCE_RELOAD =
  "masterData/POLICE_STATION_FORCE_RELOAD";
export const POLICE_STATION_SET_FILTER = "masterData/POLICE_STATION_SET_FILTER";
export const POLICE_STATION_CHANGE_ACTIVE =
  "masterData/POLICE_STATION_CHANGE_ACTIVE";

export const BANK_CREATE = "masterData/BANK_CREATE";
export const BANK_FETCH = "masterData/BANK_FETCH";
export const BANK_FETCH_BY_ID = "masterData/BANK_FETCH_BY_ID";
export const BANK_EDIT = "masterData/BANK_EDIT";
export const BANK_LOADING = "masterData/BANK_LOADING";
export const BANK_CLEAR_RESULT = "masterData/BANK_CLEAR_RESULT";
export const BANK_CHANGE_PAGE = "masterData/BANK_CHANGE_PAGE";
export const BANK_CHANGE_PAGE_SIZE = "masterData/BANK_CHANGE_PAGE_SIZE";
export const BANK_FORCE_RELOAD = "masterData/BANK_FORCE_RELOAD";
export const BANK_SET_FILTER = "masterData/BANK_SET_FILTER";

export const REQUEST_FETCH = "masterData/REQUEST_FETCH";
export const REQUEST_FETCH_BY_ID = "masterData/REQUEST_FETCH_BY_ID";
export const REQUEST_CLEAR_RESULT = "masterData/REQUEST_CLEAR_RESULT";
export const REQUEST_FORCE_RELOAD = "masterData/REQUEST_FORCE_RELOAD";
export const REQUEST_SET_FILTER = "masterData/REQUEST_SET_FILTER";

const defaultState = {
  police_station: {},
  metaPolice: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  bank: {},
  metaBank: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  request: {},
  metaRequest: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case POLICE_STATION_LOADING:
      return {
        ...state,
        metaPolice: {
          ...state.metaPolice,
          loading: action.payload,
        },
      };
    case POLICE_STATION_FETCH:
      return {
        ...state,
        police_station: action.payload,
        metaPolice: {
          ...state.metaPolice,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case POLICE_STATION_FETCH_BY_ID:
      return {
        ...state,
        police_station: {
          ...state.police_station,
          [action.payload.ORG_CODE]: action.payload,
        },
      };
    case POLICE_STATION_DELETE:
      return {
        ...state,
        permission: _.omit(state.police_station, action.payload),
      };
    case POLICE_STATION_EDIT:
      return {
        ...state,
        police_station: {
          ...state.police_station,
          [action.payload.id]: action.payload,
        },
      };
    case POLICE_STATION_CHANGE_PAGE:
      return {
        ...state,
        metaPolice: {
          ...state.metaPolice,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case POLICE_STATION_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaPolice: {
          ...state.metaPolice,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case POLICE_STATION_FORCE_RELOAD:
      return {
        ...state,
        metaPolice: {
          ...state.metaPolice,
          forceReload: ++state.metaPolice.forceReload,
        },
      };
    case POLICE_STATION_SET_FILTER:
      return {
        ...state,
        metaPolice: {
          ...state.metaPolice,
          filter: action.payload,
          page: 0,
        },
      };
    case POLICE_STATION_CLEAR_RESULT:
      return {
        ...state,
        police_station: {},
        metaPolice: {
          ...state.metaPolice,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case POLICE_STATION_CHANGE_ACTIVE:
      return {
        ...state,
        police_station: {
          ...state.police_station,
          rows: [
            ...state.police_station.rows.map((el) =>
              el.ORG_CODE === action.payload.ORG_CODE
                ? { ...el, status: action.payload.status === 1 ? true : false }
                : el
            ),
          ],
        },
      };

    case BANK_FETCH:
      return {
        ...state,
        bank: action.payload,
        metaBank: {
          ...state.metaBank,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case BANK_FETCH_BY_ID:
      return {
        ...state,
        bank: {
          ...state.bank,
          [action.payload.id]: action.payload,
        },
      };
    case BANK_CHANGE_PAGE:
      return {
        ...state,
        metaBank: {
          ...state.metaBank,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case BANK_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaBank: {
          ...state.metaBank,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case BANK_FORCE_RELOAD:
      return {
        ...state,
        metaBank: {
          ...state.metaBank,
          forceReload: ++state.metaBank.forceReload,
        },
      };
    case BANK_SET_FILTER:
      return {
        ...state,
        metaBank: {
          ...state.metaBank,
          filter: action.payload,
          page: 0,
        },
      };
    case BANK_CLEAR_RESULT:
      return {
        ...state,
        bank: {},
        metaBank: {
          ...state.metaBank,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case REQUEST_FETCH:
      return {
        ...state,
        request: action.payload,
        metaRequest: {
          ...state.metaBank,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case REQUEST_FETCH_BY_ID:
      return {
        ...state,
        request: {
          ...state.request,
          [action.payload.id]: action.payload,
        },
      };
    case REQUEST_CLEAR_RESULT:
      return {
        ...state,
        request: {},
        metaRequest: {
          ...state.metaRequest,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case REQUEST_FORCE_RELOAD:
      return {
        ...state,
        metaRequest: {
          ...state.metaRequest,
          forceReload: ++state.metaRequest.forceReload,
        },
      };
    case REQUEST_SET_FILTER:
      return {
        ...state,
        metaRequest: {
          ...state.metaRequest,
          filter: action.payload,
          page: 0,
        },
      };
    default:
      return state;
  }
}

export const policeFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(policeLoading(true));
    try {
      const response = await api.get(`/api/policestations`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: POLICE_STATION_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(policeClearResult());
      throw e;
    } finally {
      dispatch(policeLoading(false));
    }
  };

export const policeLoading = (isLoading = true) => {
  return { type: POLICE_STATION_LOADING, payload: isLoading };
};

export const policeClearResult = () => {
  return { type: POLICE_STATION_CLEAR_RESULT };
};

export const policeFetchByOrgCode = (org_code) => async (dispatch) => {
  const response = await api.get(`/api/policestations/${org_code}`);
  dispatch({ type: POLICE_STATION_FETCH_BY_ID, payload: response.data });
};

export const policeCreate = (formsValues) => async (dispatch) => {
  await api.post("/api/policestations", formsValues);
};

export const policeEdit = (id, formValues) => async (dispatch) => {
  await api.put(`/api/policestations/${id}`, formValues);
};

export const policeDelete = (id) => async (dispatch) => {
  await api.delete(`/api/policestations/${id}`);
  dispatch({ type: POLICE_STATION_DELETE, payload: id });
};

export const policeForceReload = () => {
  return { type: POLICE_STATION_FORCE_RELOAD };
};

export const policeChangePage = (page) => {
  return { type: POLICE_STATION_CHANGE_PAGE, payload: page };
};

export const policeChangePageSize = (pageSize) => {
  return { type: POLICE_STATION_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const policeSetFilter = (filterValue) => {
  return { type: POLICE_STATION_SET_FILTER, payload: filterValue };
};

export const policeChangeActive = (ORG_CODE, status) => async (dispatch) => {
  const response = await api.put(`/api/policestations/active/${ORG_CODE}`, {
    status,
  });
  dispatch({ type: POLICE_STATION_CHANGE_ACTIVE, payload: response.data });
};

export const bankFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(bankLoading(true));
    try {
      const response = await api.get(`/api/banks`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: BANK_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(bankClearResult());
      throw e;
    }
  };

export const bankLoading = (isLoading = true) => {
  return { type: BANK_LOADING, payload: isLoading };
};

export const bankClearResult = () => {
  return { type: BANK_CLEAR_RESULT };
};

export const bankFetchById = (id) => async (dispatch) => {
  const response = await api.get(`/api/banks/${id}`);
  dispatch({ type: BANK_FETCH_BY_ID, payload: response.data });
};

export const bankCreate = (formsValues) => async (dispatch) => {
  await api.post("/api/banks", formsValues);
};

export const bankEdit = (id, formValues) => async (dispatch) => {
  await api.put(`/api/banks/${id}`, formValues);
};

export const bankForceReload = () => {
  return { type: BANK_FORCE_RELOAD };
};

export const bankChangePage = (page) => {
  return { type: BANK_CHANGE_PAGE, payload: page };
};

export const bankChangePageSize = (pageSize) => {
  return { type: BANK_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const bankSetFilter = (filterValue) => {
  return { type: BANK_SET_FILTER, payload: filterValue };
};

export const requestFetch = () => {};

export const requestClearResult = () => {
  return { type: REQUEST_CLEAR_RESULT };
};

export const requestForceReload = () => {
  return { type: REQUEST_FORCE_RELOAD };
};

export const requestSetFilter = (filterValue) => {
  return { type: REQUEST_SET_FILTER, payload: filterValue };
};
