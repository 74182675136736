import {
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { FactCheck, Refresh, RemoveRedEye } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
  GridFooter,
  GridFooterContainer,
  GridToolbarContainer,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";

import {
  caseAssignClearResult,
  caseAssignForceReload,
  caseAssignSetFilter,
} from "../../../ducks/caseAssign";
import CaseAssignMultiView from "./CaseAssignMultiView";
import caseAssignSearchFormSchema from "./CaseAssignSearchForm";
import CaseAssignView from "./CaseAssignView";

export default function CaseAssignList() {
  const dispatch = useDispatch();
  const assign_case = useSelector(
    (state) => state.auth.user.permissions.assign_case ?? []
  );

  const loading = useSelector((state) => state.caseAssign.meta.loading);
  const filter = useSelector((state) => state.caseAssign.meta.filter);
  const [reload, setReload] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);

  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  const { status: assignStatus } = useParams();

  useEffect(() => {
    return () => {
      dispatch(caseAssignClearResult());
      dispatch(caseAssignSetFilter({}));
    };
  }, [dispatch, assignStatus]);

  const {
    data: caseAssignData,
    isLoading: loadingList,
    refetch: caseAssignRefetch,
  } = useQuery("caseAssign", () =>
    api
      .get("/api/assign", {
        params: {
          page: page,
          page_size: pageSize,
          ...(filter.status && { status: filter.status }),
          ...(filter.search && { search: filter.search }),
          ...(filter.policestation_org_code && {
            org_code: filter.policestation_org_code,
          }),
          ...(filter.start_date && { start_date: filter.start_date }),
          ...(filter.end_date && { end_date: filter.end_date }),
          ...(filter.create_by && { create_by: filter.create_by }),
          ...(filter.case_id && { case_id: filter.case_id }),
        },
      })
      .then((res) => res.data)
  );

  const { data: multipleAssignData, 
    isLoading: loadingMultipleAssignData,
    refetch: multipleAssignRefetch } = useQuery(
    "multipleAssignData",
    () =>
      api
        .get("/api/multiple/assign", {
          params: {
            ...(filter.status && { status: filter.status }),
            ...(filter.search && { search: filter.search }),
            ...(filter.policestation_org_code && {
              org_code: filter.policestation_org_code,
            }),
            ...(filter.start_date && { start_date: filter.start_date }),
            ...(filter.end_date && { end_date: filter.end_date }),
            ...(filter.create_by && { create_by: filter.create_by }),
            ...(filter.case_id && { case_id: filter.case_id }),
          },
        })
        .then((res) => res.data.data)
  );

  const selectAll = useCallback(() => {
    const countSelection =
      rowSelectionModel && rowSelectionModel.length
        ? rowSelectionModel.length
        : 0;
    const count =
      multipleAssignData && multipleAssignData.length
        ? multipleAssignData.length
        : 0;

    setRowSelectionModel(countSelection === count ? [] : multipleAssignData);
  }, [multipleAssignData, rowSelectionModel]);

  const selectCaseID = useCallback(
    (event, caseID) => {
      let val = [...rowSelectionModel];
      if (!val.includes(caseID)) {
        val.push(caseID);
      } else {
        const i = val.indexOf(caseID);
        if (i > -1) {
          val.splice(i, 1);
        }
      }
      setRowSelectionModel(val);
    },
    [rowSelectionModel]
  );

  useEffect(() => {
    caseAssignRefetch();
    multipleAssignRefetch();
  }, [
    page,
    pageSize,
    filter,
    caseAssignRefetch,
    reload,
    multipleAssignRefetch,
  ]);

  const renderAction = (params) => {
    return (
      <>
        {params.row.assignStatus !== "assigned" ? (
          <Tooltip title="ตรวจสอบและมอบหมายเคสนี้">
            <span>
              <IconButton
                type="button"
                variant="contained"
                size="small"
                onClick={() => {
                  handleOpenModal(
                    <CaseAssignView
                      handleCancel={handleCloseModal}
                      itemId={params.id}
                      status={params.row.assignStatus}
                      setReload={setReload}
                    />
                  );
                }}
              >
                <FactCheck color="warning" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <CaseAssignView
                  handleCancel={handleCloseModal}
                  itemId={params.id}
                  status={params.row.assignStatus}
                />
              );
            }}
          >
            <RemoveRedEye />
          </IconButton>
        )}
      </>
    );
  };
  const onSearchSubmit = (formValues) => {
    dispatch(caseAssignSetFilter(formValues));
    setRowSelectionModel([]);
  };

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerName: "",
      renderHeader: () => {
        const countSelection =
          rowSelectionModel && rowSelectionModel.length
            ? rowSelectionModel.length
            : 0;
        const count =
          multipleAssignData && multipleAssignData.length
            ? multipleAssignData.length
            : 0;
        return (
          <Checkbox
            sx={{ px: 0 }}
            checked={countSelection === count}
            indeterminate={countSelection !== 0 && countSelection < count}
            onChange={selectAll}
          />
        );
      },
      renderCell: (params) =>
        params.row.assignStatus !== "assigned" && (
          <Checkbox
            checked={rowSelectionModel.includes(params.row.id)}
            onChange={(e) => selectCaseID(e, params.row.id)}
          />
        ),
    },
    ...(assign_case.includes("view")
      ? [
          {
            field: "actions",
            headerName: "จัดการ",
            width: 100,
            renderCell: (params) => {
              return assign_case.includes("view") ? (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  sx={{ width: "100%" }}
                >
                  {renderAction(params)}
                </Stack>
              ) : (
                <></>
              );
            },
          },
        ]
      : []),
    {
      field: "id",
      headerName: "Case ID",
      sortable: true,
      width: 70,
    },
    {
      field: "assignStatus",
      headerName: "สถานะใช้งาน",
      sortable: true,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {params.row.assignStatus === "assigned" ? (
                <Chip
                  label="มอบหมายแล้ว"
                  color="success"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              ) : (
                <Chip
                  label="รอมอบหมาย"
                  color="info"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "date",
      headerName: "วันที่",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy") : undefined;
      },
    },
    {
      field: "tpo_case_id",
      headerName: "TPO ID",
    },
    {
      field: "victim_name",
      headerName: "ชื่อผู้เสียหาย",
      minWidth: 150,
    },
    {
      field: "current_org",
      headerName: "หน่วยงานเดิมที่ดูแล",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.current_org ? params.row.current_org.ORG_ABBR : "-";
      },
    },
    {
      field: "police_name",
      headerName: "ชื่อ พนง.ดูเเลเรื่อง",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.police_name ? params.row.police_name : "-";
      },
    },
    {
      field: "policestation",
      headerName: "หน่วยงานผู้ดูเเลเรื่อง",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.policestation?.ORG_ABBR ?? "-";
      },
    },
    {
      field: "assigned_by",
      headerName: "ชื่อผู้มอบหมาย",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.assigned_by
          ? `${params?.row?.rank ?? ""} ${params.row.assigned_by.fname} ${
              params.row.assigned_by.lname
            }`
          : "-";
      },
    },
    {
      field: "org_assigned_by",
      headerName: "หน่วยงานผู้มอบหมาย",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.org_assigned_by
          ? params.row.org_assigned_by.ORG_ABBR
          : "-";
      },
    },
  ];

  const openModalAll = () => {
    handleOpenModal(
      rowSelectionModel.length > 1 ? (
        <CaseAssignMultiView
          handleCancel={handleCloseModal}
          itemIds={rowSelectionModel}
          status="waiting"
          setReload={setReload}
        />
      ) : (
        <CaseAssignView
          handleCancel={handleCloseModal}
          itemId={rowSelectionModel[0]}
          status="waiting"
          setReload={setReload}
        />
      )
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">มอบหมาย Case</Typography>
        <Typography variant="body1">
          รายการเคส สำหรับารมอบหมายให้เคสมีผู้รับผิดชอบดูแลต่อไป
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          {assign_case.includes("search") && (
            <Form
              //validate={validate}
              onSubmit={onSearchSubmit}
              component={caseAssignSearchFormSchema}
              loading={loading}
              onReset={() => {
                dispatch(caseAssignSetFilter({}));
                setRowSelectionModel([]);
              }}
            />
          )}
          <DataGrid
            // density="compact"
            checkboxSelection={
              caseAssignData &&
              caseAssignData.rows.some((el) => el.assignStatus !== "assigned")
            }
            hideFooterSelectedRowCount
            rowCount={_.get(caseAssignData, "total", 0)}
            disableColumnMenu
            columns={columns}
            rows={_.get(caseAssignData, "rows", [])}
            loading={loading || loadingList||loadingMultipleAssignData}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => {
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: () =>
                CustomToolbar(rowSelectionModel, openModalAll, caseAssignData),
              Footer: () => (
                <GridFooterContainer>
                  <Typography variant="p" mx={2}>
                    {rowSelectionModel.length > 0 &&
                      rowSelectionModel.length +
                        " row" +
                        (rowSelectionModel.length > 1 ? "s" : "") +
                        " selected"}
                  </Typography>
                  {/* Add what you want here */}
                  <GridFooter
                    sx={{
                      border: "none",
                    }}
                  />
                </GridFooterContainer>
              ),
              // Footer: () => (
              //   <>
              //     <>test</> <Pagination />
              //   </>
              // ),
            }}
          />
        </Paper>
      </Grid>

      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
    </>
  );
}

function CustomToolbar(rowSelectionModel, openModalAll, caseAssignData) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.caseAssign.meta.loading);
  const btn = (
    <Button
      size="small"
      variant="outlined"
      color="success"
      disabled={rowSelectionModel && rowSelectionModel.length < 1}
      onClick={openModalAll && openModalAll}
      sx={{ ml: 2 }}
    >
      มอบหมายเคส
    </Button>
  );
  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(caseAssignForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
      {caseAssignData &&
        caseAssignData.rows.some((el) => el.assignStatus !== "assigned") && (
          <>
            {rowSelectionModel && rowSelectionModel.length < 1 ? (
              <Tooltip title="กรุณาเลือกเคสสำหรับมอบหมาย">
                <span>{btn}</span>
              </Tooltip>
            ) : (
              btn
            )}
          </>
        )}
    </GridToolbarContainer>
  );
}
