import { Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import api from "../../../apis";
import validationMsg from "../../../helpers/validationMsg";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import ProfileForm from "./ProfileForm";
import RoleInfoCard from "./RoleInfoCard";

export default function ProfileViewEditPage() {
  const isInternal = useSelector((state) => state.auth.user?.isInternal);
  const [cfChange, setCfChange] = useState(false);
  const [formData, setFormData] = useState();
  const [form, setForm] = useState();
  const handleCfChangeClose = () => setCfChange(false);
  const handleCfChangeOk = (formData, form) => {
    if (formData && form) {
      onSaveProfile(formData, form);
      setFormData();
      setForm();
      handleCfChangeClose();
    }
  };

  const validateRules = {
    rank: Joi.string().max(255).required(),
    fname: Joi.string().max(255).required(),
    lname: Joi.string().max(255).required(),
    [isInternal ? "job_title" : "position"]: Joi.string().max(255).required(),
    [isInternal ? "policestation_org_code" : "bank"]: isInternal
      ? Joi.string()
          .pattern(/^\d{5}$/)
          .required()
      : Joi.object({
          id: Joi.any(),
          name: Joi.any(),
          short_name: Joi.any(),
          code: Joi.any(),
          address: Joi.any(),
          manager_title: Joi.any(),
          type: Joi.any(),
          external_sync_status: Joi.any(),
          status: Joi.any(),
        }),
    pol_tel: Joi.string()
      .pattern(/^\d{10}$/)
      .required(),
  };
  const mapToInit = (item) => {
    return _.pick(item, Object.keys(validateRules));
  };

  const schema = Joi.object(validateRules).error(validationMsg());

  const validate = (values) => {
    const errors = {};
    const vResult = schema.validate(values, {
      abortEarly: false,
      allowUnknown: false,
    });

    if (vResult.error) {
      let details = vResult.error.details;
      details.forEach(({ context, message }) => {
        _.set(errors, context.label, message);
        errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
      });
    }

    return errors;
  };

  const [item, setItem] = useState({});
  const [editMode, setEditMode] = useState(false);
  const queryClient = useQueryClient();

  // for fetch error
  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    let isSubscribed = true;
    await api
      .get(`/api/profile`)
      .then(({ data }) => {
        // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
    };
  };

  const onSubmit = (formData, form) => {
    if (item.policestation_org_code !== formData.policestation_org_code) {
      setCfChange(true);
      setFormData(formData);
      setForm(form);
    } else {
      onSaveProfile(formData, form);
    }
  };

  const onSaveProfile = (formData, form) => {
    const obj = {
      ...formData,
      job_title: formData.position ? formData.position : formData.job_title,
    };
    return api
      .put(isInternal ? "/api/me" : "/api/external/profile", obj)
      .then(async () => {
        setEditMode(false);
        await getProfile();
        await form.reset();
        queryClient.invalidateQueries("me");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }
          normalizeKeyError[FORM_ERROR] = response.data.message;
          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  // const roleCards = useMemo(
  //   () =>
  //     item?.roles?.map((item) =>{
  //       return (<RoleInfoCard
  //       key={item.id}
  //       name={item.name}
  //       desc={item.description}
  //     />)
  //     })
  //   [item]
  // );

  return (
    <>
      <ConfirmDialog
        open={cfChange}
        onClose={handleCfChangeClose}
        cfBtnProps={{ label: "ยืนยัน", color: "error" }}
        onConfirm={() => handleCfChangeOk(formData, form)}
        title="ยืนยันการเปลี่ยนหน่วยงาน"
      >
        <Typography color="red">
          ยืนยันการเปลี่ยนหน่วยงานที่สังกัด จะส่งผลให้ case ที่ดูแลทั้งหมดหายไป
        </Typography>
      </ConfirmDialog>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* <AccountCircle fontSize="large" /> */}
          <Typography variant="h5">ข้อมูลผู้ใช้ </Typography>
          {!editMode && (
            <Button
              size="large"
              onClick={() => setEditMode(true)}
              startIcon={<Edit fontSize="inherit" />}
            >
              แก้ไข
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Form
              onSubmit={onSubmit}
              initialValues={mapToInit(item)}
              component={ProfileForm}
              readOnly={!editMode}
              onCancel={(form) => {
                form.restart();
                setEditMode(false);
              }}
              validate={validate}
              policestationOrgCode={item.policestation_org_code}
            />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">ข้อมูลสิทธิ์การใช้งาน</Typography>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 2,
                  }}
                >
                  {item?.roles?.map((item) => {
                    return (
                      <RoleInfoCard
                        key={item?.id}
                        name={item?.name}
                        desc={item?.description}
                      />
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </>
  );
}
