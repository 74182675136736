import { Check, CreditCard, RemoveRedEye } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import { getTextMask } from "../../../../helpers/imask";
import validationMsg from "../../../../helpers/validationMsg";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

const pidMask = getTextMask({
  mask: "0000000000000",
  unmask: true,
  overwrite: true,
});

export default function Hr03RequestAccountActivePidSearch({
  open,
  handleClose,
}) {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );

  const navigate = useNavigate();
  const [data, setData] = useState({ data: [], total: 0 });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isDidRequest, setsDidRequest] = useState(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "auto",
    width: isDidRequest ? "80%" : 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/openbankaccount/hr03_list", {
        params: {
          id_card: formData.pid,
          page: page,
          page_size: pageSize,
        },
      });
      if (res.data.total > 0) {
        setData(res.data);
      }
    } catch ({ response: errorRes }) {
      // return error msg if not found
      if (errorRes?.status === 404) {
        return {
          [FORM_ERROR]: "ไม่พบข้อมูล",
          case_id: "โปรดตรวจสอบข้อมูล",
          pid: "โปรดตรวจสอบข้อมูล",
        };
      }

      return { [FORM_ERROR]: "เกิดข้อผิดพลาด" };
    } finally {
      setIsLoading(false);
      setsDidRequest(true);
    }
  };
  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {(request_account_HR03.includes("view") ||
              request_account_HR03.includes("edit")) && (
              <Tooltip title="ดูข้อมูล">
                <IconButton
                  type="button"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate(`/hr03/request-active/edit/${params?.row?.id}`);
                  }}
                >
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip
                label={
                  params.row.status === "new"
                    ? "พงส. รอยืนยัน"
                    : params.row.status === "sent_to_bk"
                    ? "บก.พิจารณา"
                    : params.row.status === "bk_reject"
                    ? "บก.ปฏิเสธ"
                    : params.row.status === "sent_to_bh"
                    ? "บช.พิจารณา"
                    : params.row.status === "bh_reject"
                    ? "บช.ปฏิเสธ"
                    : params.row.status === "bh_approve"
                    ? "ตร.พิจารณา"
                    : params.row.status === "rtp_reject"
                    ? "ตร.ปฏิเสธ"
                    : params.row.status === " "
                    ? "ปปง.พิจารณา"
                    : params.row.status === "aml_approve"
                    ? "ผ่านการพิจารณา"
                    : params.row.status === "aml_reject_end"
                    ? "ไม่ผ่านข้อยุติพิจารณา"
                    : params.row.status === "aml_reject"
                    ? "ไม่ผ่านพิจารณา"
                    : params.row.status === "aml_reject_account"
                    ? "ไม่ผ่าน ดำเนินใหม่รายบัญชี"
                    : params.row.status === "rtp_approve}"
                    ? "ผ่านการพิจารณา"
                    : "-"
                }
                color="warning"
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                  },
                }}
              />
              {/* <Chip label="ใช้งาน" color="success"  /> */}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 100,
    },
    {
      field: "ref_code",
      headerName: "เลข HR03",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.hr03.ref_code}</>;
      },
    },
    {
      field: "type",
      headerName: "ประเภทคำร้อง",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.type === 1 ? "รายบัญชี" : "รายชื่อ"}</>;
      },
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.card_id ?? "-"}</>;
      },
    },
    {
      field: "full_name",
      headerName: "ชื่อ-สกุล ขอถอน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.full_name ?? "-"}</>;
      },
    },

    {
      field: "bank_id",
      headerName: "ธนาคาร",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.bank?.short_name ?? "-"}</>;
      },
    },
    {
      field: "bank_number",
      headerName: "เลขบัญชี",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.bank_number ?? "-"}</>;
      },
    },
    {
      field: "hr03.police_name.pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.hr03.pol_name + " " + params.row.hr03.pol_surname ??
              "-"}
          </>
        );
      },
    },
    {
      field: "ORG_ABBR",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.policestation.ORG_ABBR ?? "-"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  return (
    <Modal
      id="modalPid"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ตรวจสอบรายการ HR03
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="subtitle1"
          component="h2"
          color="primary"
        >
          โปรดระบุเลขบัตรประชาชนของผู้เสียหาย เพื่อตรวจสอบรายการ
        </Typography>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form validate={validate} onSubmit={onSubmit}>
            {({
              handleSubmit,
              errors,
              error,
              submitError,
              submitting,
              pristine,
              initialValues,
              submitFailed,
              form,
              values,
            }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    {submitFailed && (error || submitError) && (
                      <Grid item xs={12}>
                        <Alert severity="warning" sx={{ width: "auto" }}>
                          {error || submitError}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FieldInput
                        name="pid"
                        label="ค้นหา เลขบัตรประชาชน"
                        required
                        controlProps={{ fullWidth: true }}
                        inputProps={{
                          inputComponent: pidMask,
                          placeholder: "เลขบัตรประชาชน",
                          startAdornment: (
                            <InputAdornment position="start">
                              <CreditCard />
                            </InputAdornment>
                          ),
                        }}
                        readOnly={submitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>
                    <Grid item md={12}>
                      <Stack direction="row" spacing={2}>
                        <SubmitBtn
                          variant="contained"
                          startIcon={<Check />}
                          submitting={submitting}
                          pristine={pristine}
                        >
                          ตกลง
                        </SubmitBtn>
                        <Button
                          type="button"
                          variant="outlined"
                          disabled={submitting}
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          ยกเลิก
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </Paper>
        {isDidRequest && (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Divider textAlign="center" sx={{ mt: 2 }}>
              รายการ HR03{" "}
              <Typography component={"span"} color="warning.main">
                (ม้าดำ)
              </Typography>
            </Divider>
            <DataGrid
              // density="compact"
              rowCount={data.total ?? 0}
              disableColumnMenu
              columns={columns}
              rows={_.get(data, "data", [])}
              loading={isLoading}
              rowsPerPageOptions={[5, 10, 15, 20]}
              pagination
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageChange={(page) =>
                // dispatch(usersChangePage(page))
                setPage(page)
              }
              onPageSizeChange={(pageSize) => {
                console.log({ pageSize });
                setPageSize(pageSize);
              }}
            />
            <Divider textAlign="center" sx={{ mt: 2 }}>
              รายการ HR03{" "}
              <Typography component={"span"} color="warning.main">
                (ม้าเทาเข้ม)
              </Typography>
            </Divider>
            <DataGrid
              // density="compact"
              rowCount={data.total ?? 0}
              disableColumnMenu
              columns={columns}
              rows={_.get(data, "data", [])}
              loading={isLoading}
              rowsPerPageOptions={[5, 10, 15, 20]}
              pagination
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageChange={(page) =>
                // dispatch(usersChangePage(page))
                setPage(page)
              }
              onPageSizeChange={(pageSize) => {
                console.log({ pageSize });
                setPageSize(pageSize);
              }}
            />
          </Paper>
        )}
      </Box>
    </Modal>
  );
}

const validate = (values, props) => {
  const errors = {};
  const schema = Joi.object(validateRules()).error(validationMsg());
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

const validateRules = () => ({
  pid: Joi.string()
    .pattern(/^\d{13}$/)
    .required(),
});
